import React from "react";
import "./Changelog.css";

//imgs
import Img1 from "../assets/imgs/2024-Aug-31/1.jpg";
import Img2 from "../assets/imgs/2024-Sep-15/assignmentpage.jpg";
import Img3 from "../assets/imgs/2024-Sep-15/preferences.jpg";

function Changelog() {
  return (
    <div className="changelog-wrapper">
      <h1 className="changelog-title">Changelog</h1>
      <p className="changelog-sub">
        Here you can find the latest improvements to Notibly.
      </p>
      <div className="changelog-container" id="2024-Sep-15">
        <div className="changelog-date">
          <p>September 15th 2024</p>
        </div>
        <div className="changelog-content">
          <h1 className="changelog-big-title">
            {" "}
            Individual assignments and settings (v0.2.0){" "}
          </h1>
          <img src={Img2} alt="" loading="lazy" />
          <h1 className="changelog-mini-title"> Individual assignment page </h1>
          <p className="changelog-content-text ">
            We have now added the ability to open your assignments into their
            own page. You can now do things like:
          </p>
          <ul className="changelog-content-text list-margin">
            <li>
              Keep track of any important links, jot down quick notes related to
              the assignment
            </li>
            <li>Upload and quickly access your assignment's PDF file</li>
            <li>
              Breakdown your assignment into sub-tasks and schedule them into
              your workflow
            </li>
          </ul>
          <img src={Img3} alt="" loading="lazy" />
          <h1 className="changelog-mini-title">
            User settings and preferences
          </h1>
          <p className="changelog-content-text ">
            We have also added a new settings page which can be found in the
            main dropdown when clicking on your name in the top left of the
            navigation menu. You can see/change things like:
          </p>
          <ul className="changelog-content-text list-margin">
            <li>Reset password, change email, set first and last name</li>
            <li>You can now delete your account and all of your data</li>
            <li>See your storage usage</li>
            <li>Customize certain app behaviour</li>
          </ul>
          <h1 className="changelog-mini-title">
            General fixes and improvements
          </h1>
          <ul className="changelog-content-text list-margin">
            <li>Fixed errors with file uploading</li>
            <li>
              Recent notes on the home page now display in their proper markdown
            </li>
            <li>
              Fixed issue with calendar font failing with different languages
            </li>
            <li>Fixed black screen issue on the flashcards page</li>
          </ul>
        </div>
      </div>

      <div className="changelog-container" id="2024-Sep-8">
        <div className="changelog-date">
          <p>September 8th 2024</p>
        </div>
        <div className="changelog-content">
          <h1 className="changelog-big-title"> Post-alpha fixes (v0.1.1) </h1>
          <h1 className="changelog-mini-title"> General fixes </h1>
          <ul className="changelog-content-text list-margin">
            <li>Fixed authentication flow to support all mail clients</li>
            <li>Minor calendar style adjustments</li>
            <li>Improved adding of assignments (Now can press enter)</li>
            <li>Fixed overflow issues with notes/folders on notes page</li>
            <li>Hover text added throughout the app for button clarity</li>
            <li>
              Fixed assignments spanning across 2 days when due date time set to
              11:59pm
            </li>
            <li>
              Style of buttons and icons more consistent with long assignment or
              course names
            </li>
          </ul>
          <h1 className="changelog-mini-title"> Calendar improvements </h1>
          <ul className="changelog-content-text list-margin">
            <li>Added ability to duplicate and edit events</li>
            <li>Course information added to calendar event and popover</li>
            <li>Added ability to drag to create events</li>
            <li>
              When selecting “weekends” they now appear after Friday making the
              first day of the week Monday (This will later be able to be
              controlled in settings)
            </li>
          </ul>

          <h1 className="changelog-mini-title"> Assignment improvements </h1>
          <ul className="changelog-content-text list-margin">
            <li>New “Done” option to view completed Assignments</li>
            <li>File uploading support added (Still testing)</li>
            <li>
              Support added to upload files/documents alongside your notes.
              (Download and open only for now, later we will add an in-app
              document viewer)
            </li>
            <li>
              Each user is limited to 1GB of space, until pro plans are released
              which will give you 50GB
            </li>
            <li>50MB Max file size upload (At a time)</li>
          </ul>

          <h1 className="changelog-mini-title"> What’s next? </h1>
          <p className="changelog-content-text">
            Going forward, we are aiming for one update per week, with one major
            update once every 3-4 weeks. Here’s a few things that are next on
            our todo list:
          </p>
          <ul className="changelog-content-text list-margin">
            <ul>
              <li>Continue improving calendar UX</li>
              <li>
                Improvements of the Agenda page (Drag and drop timeboxing)
              </li>
              <li>
                Individual assignment page (Add links, files, notes etc. within
                an individual assignment page to keep things organized)
              </li>
            </ul>
          </ul>
        </div>
      </div>

      <div className="changelog-container" id="2024-Aug-31">
        <div className="changelog-date">
          <p>August 31st, 2024</p>
        </div>
        <div className="changelog-content">
          <h1 className="changelog-big-title"> Alpha Launch (v0.1.0) </h1>
          <p className="changelog-content-text">
            We’re excited to announce the release of Notibly’s alpha version! 🎉
            This is our first public release, and we’ve started with a few
            features to help you stay organized and productive. Here’s what’s
            included in v.0.1.0:
          </p>
          <h1 className="changelog-mini-title"> New Features: </h1>
          <ul className="changelog-content-text list-margin">
            <li>
              <strong>Note Taking</strong>: Capture and organize your notes all
              in one place, with easy access across all your courses.
            </li>
            <li>
              <strong>Course Organization</strong>: Keep track of all your
              classes, assignments, tasks, and materials within dedicated course
              sections.
            </li>
            <li>
              <strong>Calendar</strong>: Manage your schedule with an integrated
              calendar that syncs with your courses and assignments.
            </li>
            <li>
              <strong>Task Management</strong>: Stay on top of your to-dos with
              our Agenda page, helping you prioritize and plan out your days.
            </li>
            <li>
              <strong>Assignment Organization</strong>: Easily organize and
              manage all your assignments, with due dates.
            </li>
            <li>
              <strong>AI Flashcard Generation</strong>: Instantly convert your
              notes into flashcards using our AI-powered tool, designed to help
              you study more effectively. (This will likely need refinement as
              we go to improve its capability)
            </li>
          </ul>
          <h1 className="changelog-mini-title"> Things that are coming: </h1>
          <p className="changelog-content-text">
            This is literally just the beginning for us, we just wanted to
            finally get something out into your hands so we have something to
            build upon. We have a whole bunch of new features planned for our
            future releases, such as more AI tools, integrations, and general
            user experience improvements.
          </p>
          <p className="changelog-content-text">
            Here’s what you can expect within the next couple weeks:
          </p>
          <ul className="changelog-content-text list-margin">
            <li>
              <strong>File Uploads</strong>: Although we’re still working on
              tools to scan and summarize PDFs and slideshows, we still want to
              give you the ability to upload and store all of your course files
              for optimal organization and easy reference.
            </li>
            <li>
              <strong>Updated Event UX</strong>: We’re going to be making some
              modifications to how you create events. Right now it requires a
              little bit too much clicking for our liking, so we’ll be adapting
              it to a Google Calendar style.
            </li>
          </ul>
          <img src={Img1} alt="" loading="lazy" />
          <ul className="changelog-content-text list-margin">
            <li>
              <strong>Note taking improvements </strong>Additionally, we want to
              expand our editor to allow for features such as: formatted code
              blocks and AI writing. We also want to expand to allow linking
              between notes, to make easy connections and references between
              other notes you may have. spoiler: this may include our take on a
              mind model system… ;)
            </li>
          </ul>
          <p className="changelog-content-text">
            This is just a teenie tiny glimpse of what's ahead, and we're
            thrilled to keep building our vision with your support. Your
            feedback means the world to us—this wouldn't be possible without it!
            <br />
            Thank you so much for all of the support, and if you’re in school,
            best of luck with your semester :)
          </p>
        </div>
      </div>
    </div>
  );
}

export default Changelog;
